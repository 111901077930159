import React from "react";

import clsx from "clsx";

export interface NavContainerProps {
  children: React.ReactNode;
  className?: string;
}

export const NavContainer = React.forwardRef<HTMLDivElement, NavContainerProps>(
  function NavContainer({ className, ...props }, ref) {
    return (
      <nav
        {...props}
        className={clsx(
          "fixed top-0 z-10 flex w-full items-center bg-highlight-200 py-3 px-[22px] pl-6 pr-4 drop-shadow-sm md:h-[82px] md:gap-12 md:py-4 md:px-6",
          className,
        )}
        id="navbar"
        ref={ref}
      />
    );
  },
);
