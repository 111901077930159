import { SVG, SVGProps } from "./svg";

export function MagicWandIcon(props: SVGProps) {
  return (
    <SVG {...props} fill="none">
      <rect width="48" height="48" fill="#EFECE7" rx="24" />
      <path
        stroke="#4D2F9B"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="m23 20 3 3"
      />
      <path
        stroke="#4D2F9B"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="m29 20-3-3-13 13 3 3 13-13Zm-3-7v-1m4.9 3.1.8-.8M33 20h1m-3.1 4.9.8.8M21 15.1l-.7-.8"
      />
    </SVG>
  );
}
