import { SVG, SVGProps } from "./svg";

export function FileJsonIcon(props: SVGProps) {
  return (
    <SVG {...props} viewBox="0 0 49 48" fill="none">
      <path
        fill="#4D2F9B"
        fillRule="evenodd"
        d="M32.6 0c.6 0 1 .4 1 1v11h11a1 1 0 1 1 0 2h-12a1 1 0 0 1-1-1V1c0-.6.4-1 1-1Z"
        clipRule="evenodd"
      />
      <path
        fill="#4D2F9B"
        fillRule="evenodd"
        d="M3.6 1c0-.6.4-1 1-1h28c.3 0 .5.1.7.3l12 12c.2.2.3.4.3.7v34c0 .6-.4 1-1 1h-40a1 1 0 0 1-1-1V1Zm2 1v44h38V13.4L32.2 2H5.6Z"
        clipRule="evenodd"
      />
      <path
        fill="#4D2F9B"
        d="m11.2 32.5-1.1-.1v-1.7a13.7 13.7 0 0 1 .8.1.9.9 0 0 0 .7-.3c.2-.3.3-.7.2-1V22H14v7.1c0 .9-.2 1.8-.7 2.4a2.6 2.6 0 0 1-2.1.9Zm9.6-4.9a2.3 2.3 0 0 1-1.4 2.2 4 4 0 0 1-1.7.3l-1.3-.1c-.4 0-.7-.2-1-.4v-1.9a6.4 6.4 0 0 0 2.4.7c.2 0 .5 0 .7-.2a.5.5 0 0 0 .2-.4l-.1-.3a1 1 0 0 0-.3-.2l-1-.5A5 5 0 0 1 16 26a2 2 0 0 1-.6-.8l-.1-1a2 2 0 0 1 .8-1.7 4 4 0 0 1 2.1-.6 6 6 0 0 1 2.5.6l-.6 1.6c-.6-.3-1.3-.4-2-.5a1 1 0 0 0-.5.2.4.4 0 0 0-.2.3.5.5 0 0 0 .2.5l1.3.6c.6.2 1 .6 1.4 1 .3.4.4.8.4 1.3Zm8.6-1.6c0 1.2-.3 2.2-1 3a3.7 3.7 0 0 1-2.8 1.1 3.7 3.7 0 0 1-2.9-1 5 5 0 0 1-1-3c0-1.1.3-2.2 1-3a3.7 3.7 0 0 1 2.9-1 3.7 3.7 0 0 1 2.8 1c.7.8 1 1.9 1 3ZM24 26c0 1.6.5 2.3 1.6 2.3a1.4 1.4 0 0 0 1.2-.5A3 3 0 0 0 27 26a3 3 0 0 0-.3-1.8 1.3 1.3 0 0 0-1.2-.5c-1.1 0-1.6.7-1.6 2.3Zm14.3 4h-2.8l-2.9-5.5V30h-1.8v-7.9h2.8l2.8 5.5v-5.5h1.9V30Z"
      />
    </SVG>
  );
}
