import { SVG, SVGProps } from "./svg";

export function BullseyeIcon(props: SVGProps) {
  return (
    <SVG {...props} fill="none">
      <rect width="48" height="48" fill="#EFECE7" rx="24" />
      <path
        fill="#4D2F9B"
        fillRule="evenodd"
        d="M24 16a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm-10 8a10 10 0 1 1 20 0 10 10 0 0 1-20 0Z"
        clipRule="evenodd"
      />
      <path
        fill="#4D2F9B"
        fillRule="evenodd"
        d="M25 12v6h-2v-6h2Zm5 11h6v2h-6v-2Zm-5 7v6h-2v-6h2Zm-13-7h6v2h-6v-2Zm10 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Z"
        clipRule="evenodd"
      />
      <path fill="#4D2F9B" d="M24 25a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
    </SVG>
  );
}
