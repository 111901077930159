import { SVG, SVGProps } from "./svg";

export function FileCsvIcon(props: SVGProps) {
  return (
    <SVG {...props} viewBox="0 0 48 48" fill="none">
      <path
        fill="#4D2F9B"
        fillRule="evenodd"
        d="M32 0a1 1 0 0 1 1 1v11h11a1 1 0 1 1 0 2H32a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1Z"
        clipRule="evenodd"
      />
      <path
        fill="#4D2F9B"
        fillRule="evenodd"
        d="M3 1a1 1 0 0 1 1-1h28a1 1 0 0 1 .7.3l12 12a1 1 0 0 1 .3.7v34a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V1Zm2 1v44h38V13.41L31.59 2H5Z"
        clipRule="evenodd"
      />
      <path
        fill="#4D2F9B"
        d="M17.79 23.77c-.5 0-.9.21-1.19.63a3 3 0 0 0-.42 1.7c0 1.52.57 2.27 1.72 2.27.35 0 .69-.05 1.01-.14a13 13 0 0 0 .99-.35v1.8c-.66.28-1.4.43-2.23.43-1.18 0-2.1-.35-2.72-1.03A4.3 4.3 0 0 1 14 26.1c0-.82.15-1.53.46-2.15a3.3 3.3 0 0 1 1.32-1.42 4 4 0 0 1 2.03-.5c.85 0 1.66.19 2.43.56l-.65 1.67a7.48 7.48 0 0 0-.87-.35c-.29-.09-.6-.14-.93-.14Zm8.77 3.84a2.32 2.32 0 0 1-1.44 2.18c-.46.21-1.01.32-1.64.32a4.83 4.83 0 0 1-2.42-.5v-1.89a6.28 6.28 0 0 0 2.47.66c.31 0 .54-.05.69-.16a.5.5 0 0 0 .22-.42.5.5 0 0 0-.09-.29 1.05 1.05 0 0 0-.28-.24 11.8 11.8 0 0 0-1.05-.5c-.5-.24-.9-.46-1.15-.68a2.07 2.07 0 0 1-.57-.75 2.49 2.49 0 0 1-.18-1 2 2 0 0 1 .79-1.7 3.5 3.5 0 0 1 2.17-.6c.8 0 1.64.18 2.48.56l-.65 1.64a4.72 4.72 0 0 0-1.9-.5c-.27 0-.47.04-.6.14a.44.44 0 0 0-.19.36c0 .15.08.29.24.41a9 9 0 0 0 1.3.67c.67.3 1.14.63 1.4.98.27.35.4.79.4 1.31Zm5.63-5.46h2.37L31.99 30h-2.5l-2.56-7.85h2.38l1.07 3.98c.22.88.34 1.5.37 1.84a15.3 15.3 0 0 1 .35-1.83l1.09-4Z"
      />
    </SVG>
  );
}
