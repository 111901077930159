import { SVG, SVGProps } from "./svg";

export function DocumentExtractIcon(props: SVGProps) {
  return (
    <SVG {...props} fill="none">
      <rect width="48" height="48" fill="#EFECE7" rx="24" />
      <path fill="#4D2F9B" d="M14 12h18v10h-2v-8H16v20h10v2H14V12Z" />
      <path
        fill="#4D2F9B"
        d="m32 31.6 2-2 1.4 1.4-4.4 4.4-4.4-4.4 1.4-1.4 2 2V25h2v6.6ZM18 17h2v2h-2v-2Zm0 4h2v2h-2v-2Zm0 4h10v2H18v-2Zm0 4h6v2h-6v-2Zm4-12h2v2h-2v-2Zm0 4h2v2h-2v-2Zm4-4h2v2h-2v-2Zm0 4h2v2h-2v-2Z"
      />
    </SVG>
  );
}
