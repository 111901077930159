import React from "react";

import clsx from "clsx";
import range from "lodash/range";

import { useUi } from "common/context/ui";
import { HamburgerCloseIcon } from "common/icons/hamburger-close";
import { HamburgerOpenIcon } from "common/icons/hamburger-open";

export interface NavMenuButtonProps
  extends Omit<React.ComponentPropsWithoutRef<"button">, "children"> {}

export const NavMenuButton = React.forwardRef<
  HTMLButtonElement,
  NavMenuButtonProps
>(function NavMenuButton({ className, onClick, ...props }, ref) {
  const { toggleNavMenu, navMenuOpen } = useUi();

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    toggleNavMenu();

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <button
      {...props}
      className={clsx(
        "inline-flex h-10 w-10 flex-col items-center justify-center text-accent-500 lg:hidden",
        className,
      )}
      onClick={handleClick}
      ref={ref}
      type="button"
    >
      {navMenuOpen ? (
        <HamburgerOpenIcon height={24} />
      ) : (
        <HamburgerCloseIcon height={28} />
      )}
    </button>
  );
});
