import React from "react";

import clsx from "clsx";

import { ElementProps } from "../types/components";

export interface AvatarProps extends ElementProps<"span"> {
  size?: "md";
  color?: "light";
}

export const Avatar = React.forwardRef<HTMLSpanElement, AvatarProps>(
  function Avatar({ size = "md", className, color = "light", ...props }, ref) {
    return (
      <span
        {...props}
        className={clsx(
          "inline-flex flex-shrink-0 flex-grow-0 items-center justify-center rounded-full text-accent-500 shadow-sm",
          {
            "h-12 w-12 text-2xl": size === "md",
            "bg-highlight-300": color === "light",
          },
          className,
        )}
        ref={ref}
      />
    );
  },
);
