import { t, Trans } from "@lingui/macro";

import { BullseyeIcon } from "common/icons/bullseye";
import { DocumentExtractIcon } from "common/icons/document-extract";
import { FramedExtractIcon } from "common/icons/framed-extract";
import { FramedEyeIcon } from "common/icons/framed-eye";
import { MagicWandIcon } from "common/icons/magic-wand";
import { MoleculeIcon } from "common/icons/molecule";
import { Section } from "common/ui/section";
import { Typography } from "common/ui/typography";

const featuresSectionItems = [
  {
    icon: DocumentExtractIcon,
    title: t`Extract table data`,
    text: t`Financial reports, bank statements, contact lists and more - extract valuable data trapped in your PDF documents.`,
  },
  {
    icon: FramedExtractIcon,
    title: t`Capture form field data`,
    text: t`Purchase orders, HR forms, payroll and more - get all the information you need from your business forms.`,
  },
  {
    icon: BullseyeIcon,
    title: t`Unrivalled accuracy`,
    text: t`Built for and tested on financial datasets, we're benchmarked at 96.6% output accuracy - higher than any other extraction tool.`,
  },
  {
    icon: FramedEyeIcon,
    title: t`Smart data detection`,
    text: t`Advanced OCR and AI technology identifies the data in your documents automatically, so you don't have to.`,
  },
  {
    icon: MagicWandIcon,
    title: t`Fully automated`,
    text: t`Save hours of time by automating your data extraction process from start to finish, and enjoy data entry to output in minutes.`,
  },
  {
    icon: MoleculeIcon,
    title: t`Workflow integration`,
    text: t`Integrate Parsel's extraction technology directly into your business workflows via our customisable API.`,
  },
];

export function HomepageSectionFeatures() {
  return (
    <Section id="features" className="md:mt-0">
      <Typography variant="h2" className="mb-10 md:mb-14" gutterBottom={false}>
        <Trans>
          Smart data extraction <br />
          for smart businesses
        </Trans>
      </Typography>
      <div className="grid grid-cols-1 gap-y-14 md:grid-cols-3 md:gap-x-8 md:gap-y-20">
        {featuresSectionItems.map(({ icon: Icon, title, text }) => (
          <div key={title}>
            <Icon size={48} className="mb-4" />
            <Typography variant="h3">{title}</Typography>
            <Typography paragraph gutterBottom={false}>
              {text}
            </Typography>
          </div>
        ))}
      </div>
    </Section>
  );
}
