import { SVG, SVGProps } from "./svg";

export function FileTxtIcon(props: SVGProps) {
  return (
    <SVG {...props} viewBox="0 0 49 48" fill="none">
      <path
        fill="#4D2F9B"
        fillRule="evenodd"
        d="M32.2 0c.6 0 1 .4 1 1v11h11a1 1 0 1 1 0 2h-12a1 1 0 0 1-1-1V1c0-.6.4-1 1-1Z"
        clipRule="evenodd"
      />
      <path
        fill="#4D2F9B"
        fillRule="evenodd"
        d="M3.2 1c0-.6.4-1 1-1h28c.3 0 .5.1.7.3l12 12c.2.2.3.4.3.7v34c0 .6-.4 1-1 1h-40a1 1 0 0 1-1-1V1Zm2 1v44h38V13.4L31.8 2H5.2Z"
        clipRule="evenodd"
      />
      <path
        fill="#4D2F9B"
        d="M18.2 30h-2v-6.1h-2V22h6v2h-2v6Zm10.1 0H26l-1.5-2.5-1.6 2.5h-2.4l2.7-4-2.5-3.9H23l1.4 2.4 1.3-2.4h2.5l-2.5 4 2.6 3.9Zm4.4 0h-2.1v-6.1h-2V22h6v2h-2v6Z"
      />
    </SVG>
  );
}
