import React from "react";

import clsx from "clsx";

export interface SectionProps
  extends React.ComponentPropsWithoutRef<"section"> {
  gutter?: boolean;
}

export const Section = React.forwardRef<HTMLElement, SectionProps>(
  function Section({ className, gutter = true, ...props }, ref) {
    return (
      <section
        {...props}
        className={clsx(
          "max-w-6xl px-6 md:mx-auto",
          {
            "py-10 md:py-[104px]": gutter,
          },
          className,
        )}
        ref={ref}
      />
    );
  },
);
