import { SVG, SVGProps } from "./svg";

export function FramedEyeIcon(props: SVGProps) {
  return (
    <SVG {...props} fill="none">
      <rect width="48" height="48" fill="#EFECE7" rx="24" />
      <path
        stroke="#4D2F9B"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M14 24s4-6 10-6 10 6 10 6-4 6-10 6-10-6-10-6Z"
      />
      <path
        stroke="#4D2F9B"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M24 26a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm-10-8v-4h4m16 4v-4h-4M14 30v4h4m16-4v4h-4"
      />
    </SVG>
  );
}
