import { SVG, SVGProps } from "./svg";

export function FileXlsxIcon(props: SVGProps) {
  return (
    <SVG {...props} viewBox="0 0 49 48" fill="none">
      <path
        fill="#4D2F9B"
        fillRule="evenodd"
        d="M32.8 0c.6 0 1 .4 1 1v11h11a1 1 0 1 1 0 2h-12a1 1 0 0 1-1-1V1c0-.6.4-1 1-1Z"
        clipRule="evenodd"
      />
      <path
        fill="#4D2F9B"
        fillRule="evenodd"
        d="M3.8 1c0-.6.4-1 1-1h28c.3 0 .5.1.7.3l12 12c.2.2.3.4.3.7v34c0 .6-.4 1-1 1h-40a1 1 0 0 1-1-1V1Zm2 1v44h38V13.4L32.4 2H5.8Z"
        clipRule="evenodd"
      />
      <path
        fill="#4D2F9B"
        d="M18.7 30h-2.5l-1.5-2.5-1.5 2.5h-2.4l2.6-4-2.4-4h2.3l1.4 2.4 1.4-2.4h2.4L16 26l2.7 3.9Zm.9 0v-8h2.1v6.2h3V30h-5.1Zm11.5-2.4a2.3 2.3 0 0 1-1.4 2.2 4 4 0 0 1-1.7.3l-1.3-.1-1-.4v-2a6.3 6.3 0 0 0 2.4.7l.7-.1.2-.4v-.3l-.4-.3-1-.5a5 5 0 0 1-1.2-.6 2 2 0 0 1-.5-.8c-.2-.3-.2-.6-.2-1 0-.7.2-1.3.8-1.7.5-.4 1.2-.6 2.1-.6.8 0 1.7.2 2.5.6l-.6 1.6c-.8-.3-1.4-.5-2-.5l-.5.1c-.2.1-.2.3-.2.4 0 .2 0 .3.2.4l1.3.7c.7.3 1.2.6 1.4 1 .3.3.4.8.4 1.3Zm8.3 2.4H37l-1.6-2.5L34 30h-2.4l2.6-4-2.4-3.9H34l1.4 2.4 1.4-2.4h2.4l-2.5 4 2.7 3.9Z"
      />
    </SVG>
  );
}
