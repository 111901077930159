import { SVG, SVGProps } from "./svg";

export function HamburgerOpenIcon(props: SVGProps) {
  return (
    <SVG {...props} viewBox="0 0 24 24">
      <path
        d="M12 9.9002L21.4499 0.450195L23.55 2.55019L14.1 12.0002L23.55 21.4502L21.4499 23.5502L12 14.1002L2.54995 23.5502L0.449951 21.4502L9.89995 12.0002L0.449951 2.55019L2.54995 0.450195L12 9.9002Z"
        fill="currentColor"
      />
    </SVG>
  );
}
