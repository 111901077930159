import { SVG, SVGProps } from "./svg";

export function MoleculeIcon(props: SVGProps) {
  return (
    <SVG {...props} fill="none">
      <rect width="48" height="48" fill="#EFECE7" rx="24" />
      <path
        stroke="#4D2F9B"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M24 17v5m0 4v5m-6.3-12.5 4.6-2.5m8 2.5L25.7 16m-8 13.5 4.6 2.5m8-2.5L25.7 32M16 21.5v5m16-5v5M22.3 23l-4.6-2.5m8 2.5 4.6-2.5m-8 4.5-4.6 2.5m8-2.5 4.6 2.5m-14.3 3a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0-9a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm16 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0-9a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM24 26a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0-9a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      />
    </SVG>
  );
}
