import { SVG, SVGProps } from "./svg";

export function HamburgerCloseIcon(props: SVGProps) {
  return (
    <SVG {...props} viewBox="0 0 28 18">
      <line y1="1" x2="28" y2="1" stroke="currentColor" strokeWidth="2" />
      <line y1="9" x2="28" y2="9" stroke="currentColor" strokeWidth="2" />
      <line y1="17" x2="28" y2="17" stroke="currentColor" strokeWidth="2" />
    </SVG>
  );
}
