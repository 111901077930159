import Link from "next/link";

import { ParselIcon } from "common/icons/parsel";

export function NavLogoLink() {
  return (
    <Link href="/" passHref>
      <a>
        <ParselIcon />
      </a>
    </Link>
  );
}
