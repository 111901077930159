import { NavContainer } from "./nav-container";
import { NavLink } from "./nav-link";
import { NavLogoLink } from "./nav-logo-link";
import { NavMenu } from "./nav-menu";
import { NavMenuButton } from "./nav-menu-button";
import { NavMenuDivider } from "./nav-menu-divider";
import { NavMenuSection } from "./nav-menu-section";

export const Nav = Object.assign({} as typeof NavContainer, NavContainer, {
  MenuButton: NavMenuButton,
  Link: NavLink,
  LogoLink: NavLogoLink,
  Menu: NavMenu,
  MenuDivider: NavMenuDivider,
  MenuSection: NavMenuSection,
});

export type { NavContainerProps } from "./nav-container";
export type { NavLinkProps } from "./nav-link";
export type { NavMenuProps } from "./nav-menu";
export type { NavMenuSectionProps } from "./nav-menu-section";
