import { t } from "@lingui/macro";
import clsx from "clsx";
import Image from "next/image";

import { FileApiIcon } from "common/icons/file-api";
import { FileCsvIcon } from "common/icons/file-csv";
import { FileJsonIcon } from "common/icons/file-json";
import { FileTxtIcon } from "common/icons/file-txt";
import { FileTxtJsonIcon } from "common/icons/file-txt-json";
import { FileXlsxIcon } from "common/icons/file-xlsx";
import { Section } from "common/ui/section";
import { Typography } from "common/ui/typography";

import whyParsel1 from "./images/why-parsel-1.png";
import whyParsel2 from "./images/why-parsel-2.png";
import whyParsel3 from "./images/why-parsel-3.png";
import whyParsel4 from "./images/why-parsel-4.png";

const whyParselSectionItems = [
  {
    title: t`API integration`,
    heading: t`Send data directly to your business applications`,
    content: () => (
      <Typography paragraph>
        {t`Integrate our API with your systems to streamline data entry and send data outputs directly into your business applications - without disrupting your workflows.`}
      </Typography>
    ),
    image: whyParsel1,
  },
  {
    title: t`Unrivalled accuracy`,
    heading: t`Boost efficiency with data exports you can trust`,
    content: () => (
      <Typography paragraph>
        {t`Parsel is benchmarked at 96.6% accuracy on financial documents - more than any other tool on the market - so you can trust your data to contain less errors and require less corrections.`}
      </Typography>
    ),
    image: whyParsel2,
  },
  {
    title: t`Fully automated`,
    heading: t`Save time with automated data extraction`,
    content: () => (
      <>
        <Typography paragraph gutterBottom={false}>
          {t`Cut out costly manual processes with a fully automated solution.
              Simply connect to our API and let our AI automatically identify
              and extract your data, in minutes.`}
        </Typography>
        <div className="mb-14 flex flex-col items-center md:mb-0 md:flex-row">
          <Typography
            variant="button"
            as="h5"
            className="mb-4 w-full md:mb-0 md:mr-4 md:mt-2 md:w-auto"
          >
            {t`Export as`}
          </Typography>
          <div className="flex w-full flex-1 justify-between md:justify-start md:gap-4">
            <FileJsonIcon size={48} />
            <FileXlsxIcon size={48} />
            <FileCsvIcon size={48} />
            <FileTxtIcon size={48} />
          </div>
        </div>
      </>
    ),
    image: whyParsel3,
  },
  {
    title: t`Fully encrypted`,
    heading: t`Store your data securely, ready for when you need it`,
    content: () => (
      <Typography paragraph>
        {t`We encrypt your documents from point of upload to point of download in your desired output. All files are processed and stored securely in transit and in your own private virtual environment.`}
      </Typography>
    ),
    image: whyParsel4,
  },
];

export function HomepageSectionWhyParsel() {
  return (
    <Section id="why-parsel">
      <div className="flex flex-col gap-y-28 md:gap-y-[104px]">
        {whyParselSectionItems.map(
          ({ title, heading, content, image }, index) => (
            <div
              key={title}
              className={clsx(
                "flex flex-col md:flex-row md:items-center md:gap-x-[130px]",
                {
                  "md:flex-row-reverse": index % 2 !== 0,
                },
              )}
            >
              <div className="flex flex-1 flex-col gap-y-6 md:w-[554px]">
                <Typography variant="overline" as="h3">
                  {title}
                </Typography>
                <Typography variant="h2" as="h4" gutterBottom={false}>
                  {heading}
                </Typography>
                {content()}
              </div>
              <div className="flex-1">
                <Image
                  src={image}
                  alt={title}
                  placeholder="blur"
                  layout="responsive"
                  sizes="(min-width: 1140px) 25vw, 50vw"
                  lazyBoundary="400px"
                />
              </div>
            </div>
          ),
        )}
      </div>
    </Section>
  );
}
