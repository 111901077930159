import React from "react";

import { Tab as HeadlessTab } from "@headlessui/react";
import type { Props as HeadlessProps } from "@headlessui/react/dist/types";
import clsx from "clsx";

import { ElementProps } from "common/types/components";

interface ClassNameRenderProp {
  selected: boolean;
}

type TabClassName = string | ((props: ClassNameRenderProp) => string);

export interface TabProps extends Omit<ElementProps<"button">, "className"> {
  className?: TabClassName;
  selectedOverride?: boolean;
}

const _Tab = React.forwardRef<HTMLButtonElement, TabProps>(function Tab(
  { className, children, selectedOverride, ...props },
  ref,
) {
  return (
    <HeadlessTab
      {...props}
      as="button"
      className={(props) =>
        clsx(
          "w-[236px] whitespace-nowrap font-bold uppercase tracking-caption",
          {
            "text-gray-500": selectedOverride ?? props.selected,
            "text-gray-300 hover:border-b-gray-500 hover:border-opacity-80": !(
              selectedOverride ?? props.selected
            ),
          },
          typeof className === "function" ? className(props) : className,
        )
      }
      ref={ref}
    >
      <span className="inline-block border-b-2 border-b-current pb-2 leading-none md:px-3">
        {children}
      </span>
    </HeadlessTab>
  );
});

export interface TabListProps
  extends Omit<HeadlessProps<typeof HeadlessTab["List"]>, "children"> {
  children: React.ReactNode;
}
const List = React.forwardRef<HTMLDivElement, TabListProps>(function List(
  { className, children, ...props },
  ref,
) {
  return (
    <HeadlessTab.List
      {...props}
      as="div"
      className={clsx("flex gap-x-5 overflow-x-auto", className)}
      ref={ref}
    >
      {children}
    </HeadlessTab.List>
  );
});

export type TabGroupProps = HeadlessProps<typeof HeadlessTab["Group"]>;
const Group = HeadlessTab.Group;

export type TabPanelsProps = HeadlessProps<typeof HeadlessTab["Panels"]>;
const Panels = HeadlessTab.Panels;

export type TabPanelProps = HeadlessProps<typeof HeadlessTab["Panel"]>;
const Panel = HeadlessTab.Panel;

export const Tab = Object.assign(_Tab, { Group, Panels, Panel, List });
