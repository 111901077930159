import React from "react";

import { Transition } from "@headlessui/react";
import { t } from "@lingui/macro";

import { Link } from "common/components/link";
import { Nav } from "common/components/nav";
import { useUi } from "common/context/ui";
import { Button } from "common/ui/button";

export function HomepageNav() {
  const { navMenuOpen } = useUi();
  return (
    <Nav>
      <Nav.LogoLink />
      <div className="hidden items-center gap-x-14 lg:flex">
        <Nav.Link href="#features">{t`Features`}</Nav.Link>
        <Nav.Link href="#how-it-works">{t`How it works`}</Nav.Link>
        <Nav.Link href="#use-cases">{t`Use cases`}</Nav.Link>
        <Nav.Link href="#why-parsel">{t`Why Parsel?`}</Nav.Link>
        <Nav.Link href="#pricing">{t`Pricing`}</Nav.Link>
      </div>
      <div className="ml-auto flex items-center gap-x-6">
        <div className="hidden items-center gap-x-6 lg:flex">
          <Nav.Link href="/logIn">{t`Log in`}</Nav.Link>
        </div>
      </div>
      <Nav.Menu>
        <Nav.MenuSection>
          <Nav.Link href="#features">{t`Features`}</Nav.Link>
          <Nav.Link href="#how-it-works">{t`How it works`}</Nav.Link>
          <Nav.Link href="#use-cases">{t`Use cases`}</Nav.Link>
          <Nav.Link href="#why-parsel">{t`Why Parsel?`}</Nav.Link>
          <Nav.Link href="#pricing">{t`Pricing`}</Nav.Link>
        </Nav.MenuSection>
        <Nav.MenuDivider />
        <Nav.MenuSection>
          <Nav.Link href="/logIn">{t`Log in`}</Nav.Link>
        </Nav.MenuSection>
      </Nav.Menu>
    </Nav>
  );
}
