import { t } from "@lingui/macro";
import clsx from "clsx";
import Image from "next/image";

import { Avatar } from "common/ui/avatar";
import { Section } from "common/ui/section";
import { Typography } from "common/ui/typography";

import howItWorks1 from "./images/how-it-works-1.png";
import howItWorks2 from "./images/how-it-works-2.png";
import howItWorks3 from "./images/how-it-works-3.png";

const howItWorksSectionItems = [
  {
    title: t`You upload your documents`,
    text: t`Upload your unstructured documents via drag and drop interface on our web application or directly into our Enterprise API. `,
    image: howItWorks1,
    imageAlt: t`Drag and drop feature`,
  },
  {
    title: t`We identify & extract the data`,
    text: t`Advanced AI and OCR work in tandem to identify data in your documents automatically, and extract it in minutes.`,
    image: howItWorks2,
    imageAlt: t`Example document`,
  },
  {
    title: t`You export your files`,
    text: t`Export as ready-to-use Excel, JSON or Text files, or send your data directly to your business applications via API.`,
    image: howItWorks3,
    imageAlt: t`Example dataset`,
  },
];

export function HomepageSectionHowItWorks() {
  return (
    <Section id="how-it-works">
      <Typography
        variant="h2"
        className="mb-10 md:mb-14"
        align="center"
        gutterBottom={false}
      >
        {t`How it works`}
      </Typography>
      <div className="md:gap-x-[ grid grid-cols-1 gap-y-16 gap-x-8 md:grid-cols-3">
        {howItWorksSectionItems.map(
          ({ title, text, image, imageAlt }, index) => (
            <div className="flex flex-col items-center gap-y-0" key={title}>
              <Avatar className="mb-4 md:mb-6">{index + 1}</Avatar>
              <Typography
                align="center"
                variant="h3"
                className="whitespace-nowrap"
              >
                {title}
              </Typography>
              <Typography align="center" paragraph gutterBottom={false}>
                {text}
              </Typography>
              <div className="mt-8 w-full flex-1 px-2 md:mt-14 md:px-6">
                <Image
                  src={image}
                  alt={imageAlt}
                  placeholder="blur"
                  layout="responsive"
                  sizes="(min-width: 1140px) 25vw, 50vw"
                  lazyBoundary="400px"
                />
              </div>
            </div>
          ),
        )}
      </div>
    </Section>
  );
}
