import React from "react";

import clsx from "clsx";

import { Link } from "../link";
import type { LinkProps } from "../link";

export interface NavLinkProps extends LinkProps {}

export const NavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>(
  function NavLink({ className, href, ...props }, ref) {
    return (
      <Link
        {...props}
        className={clsx(
          "whitespace-nowrap rounded-md px-6 py-2 text-xl text-accent-500",
          "hover:text-pviolet-500 hover:underline hover:underline-offset-1",
          "lg:w-auto lg:px-2 lg:text-base",
          className,
        )}
        href={href}
        ref={ref}
      />
    );
  },
);
