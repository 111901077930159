import { SVG, SVGProps } from "./svg";

export function FramedExtractIcon(props: SVGProps) {
  return (
    <SVG {...props} fill="none">
      <rect width="48" height="48" fill="#EFECE7" rx="24" />
      <path
        stroke="#4D2F9B"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M26 34h-4m4-20h-4m12 12v-4m-20 4v-4m17 12h3v-3m-20 0v3h3m0-20h-3v3m20 0v-3h-3"
      />
      <path
        stroke="#4D2F9B"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M24 18v11"
      />
      <path
        stroke="#4D2F9B"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="m20 25 4 4 4-4"
      />
    </SVG>
  );
}
