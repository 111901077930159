import React, { createContext, useEffect, useRef } from "react";

import { useRouter } from "next/router";

import { Dialog, Transition } from "@headlessui/react";
import XIcon from "@heroicons/react/outline/XIcon";

import { useUi } from "common/context/ui";
import { ElementProps } from "common/types/components";

import { NavLogoLink } from "./nav-logo-link";

export interface NavMenuProps extends Omit<ElementProps<"div">, "children"> {
  children: React.ReactNode | React.ReactNode[];
}

function noop() {}

interface NavMenuContextShape {
  isInNavMenu: boolean;
}

const NavMenuContext = createContext<NavMenuContextShape>({
  isInNavMenu: false,
});

export const NavMenu = React.forwardRef<HTMLDivElement, NavMenuProps>(
  function NavMenu({ children, ...props }, ref) {
    const { navMenuOpen, toggleNavMenu } = useUi();
    const { asPath } = useRouter();
    const prevPath = useRef<string>();

    useEffect(() => {
      if (navMenuOpen) {
        const resizeHandler = () => {
          if (window.innerWidth > 1024) {
            toggleNavMenu(false);
          }
        };

        const keydownHandler = (event: KeyboardEvent) => {
          if (event.key === "Escape") {
            toggleNavMenu(false);
          }
        };

        window.addEventListener("resize", resizeHandler);
        window.addEventListener("keydown", keydownHandler);
        return () => {
          window.removeEventListener("resize", resizeHandler);
          window.removeEventListener("keydown", keydownHandler);
        };
      }
    }, [navMenuOpen, toggleNavMenu]);

    useEffect(() => {
      if (navMenuOpen && asPath !== prevPath.current) {
        toggleNavMenu(false);
      }
    }, [asPath, navMenuOpen, toggleNavMenu]);

    useEffect(() => {
      prevPath.current = asPath;
    });

    return (
      <NavMenuContext.Provider value={{ isInNavMenu: true }}>
        <Transition
          as={React.Fragment}
          show={navMenuOpen}
          enter="duration-300 transform ease-in-out"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="duration-200 transform ease-in-out"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <Dialog
            open
            onClose={noop}
            className="fixed top-[65px] bottom-0 z-10 flex w-full flex-col overflow-y-auto border-t border-t-gray-100 bg-highlight-200"
          >
            <Dialog.Panel
              {...props}
              className="flex flex-col gap-y-6 py-6"
              ref={ref}
            >
              {children}
            </Dialog.Panel>
          </Dialog>
        </Transition>
      </NavMenuContext.Provider>
    );
  },
);
