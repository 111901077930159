import type { NextPage } from "next";

import {
  HomepageHero,
  HomepageNav,
  HomepageSectionFeatures,
  HomepageSectionHowItWorks,
  HomepageSectionPricing,
  HomepageSectionStartUsing,
  HomepageSectionUseCases,
  HomepageSectionWhyParsel,
} from "../components/homepage";

const Home: NextPage = () => {
  return (
    <>
      <HomepageNav />
      <main>
        <HomepageHero />
        <HomepageSectionFeatures />
        <HomepageSectionHowItWorks />
        <HomepageSectionUseCases />
        <HomepageSectionWhyParsel />
      </main>
    </>
  );
};

export default Home;
