import { Trans, t } from "@lingui/macro";

import { CallToActionLink } from "common/components/call-to-action-link";
import { Section } from "common/ui/section";
import { Typography } from "common/ui/typography";

import videoPosterImage from "./images/video-poster.png";

export function HomepageHero() {
  return (
    <Section className="md:mt-10">
      <div className="flex flex-col items-stretch md:flex-row">
        <div className="flex flex-1 flex-col gap-y-6 md:mr-[123px] md:max-w-[463px] md:gap-y-8">
          <Typography variant="h1" gutterBottom={false}>
            {t`Automated Data Extraction. Unrivalled Accuracy.`}
          </Typography>
          <Typography paragraph gutterBottom={false}>
            <Trans>
              Save time with automated data entry.
              <br />
              Harness AI to extract data from documents in minutes.
              <br />
              Export directly to your business applications.
            </Trans>
          </Typography>
        </div>
        <div className="mt-10 flex min-h-[180px] flex-1 md:mt-0">
          <iframe
            src="https://player.vimeo.com/video/760268466?h=6724ee634d&title=0&byline=0&portrait=0"
            width="1920"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title={t`Parsel Automated Data Extraction. Unrivalled Accuracy.`}
            className="aspect-video h-full w-full flex-1 md:max-w-[554px]"
          />
        </div>
      </div>
    </Section>
  );
}
