import React, { useCallback, useState } from "react";

import { Transition } from "@headlessui/react";
import { t } from "@lingui/macro";
import clsx from "clsx";
import Image from "next/image";

import { Section } from "common/ui/section";
import { Tab } from "common/ui/tabs";
import { Typography } from "common/ui/typography";

import useCases11 from "./images/use-cases-11.png";
import useCases12 from "./images/use-cases-12.png";
import useCases13 from "./images/use-cases-13.png";
import useCases21 from "./images/use-cases-21.png";
import useCases22 from "./images/use-cases-22.png";
import useCases23 from "./images/use-cases-23.png";
import useCases31 from "./images/use-cases-31.png";
import useCases32 from "./images/use-cases-32.png";
import useCases33 from "./images/use-cases-33.png";

const getUseCasesSectionItems = () => [
  {
    label: t`Financial`,
    title: t`Unlock the value of financial documents`,
    text: t`Take advantage of unrivalled accuracy when extracting important company information.`,
    items: [
      {
        title: t`Balance sheets`,
        text: t`Extract vital business data into spreadsheets to track changes over time.`,
        image: useCases11,
      },
      {
        title: t`Income statements`,
        text: t`Pull revenue and expenses data and export it directly into your reporting structures.`,
        image: useCases12,
      },
      {
        title: t`Cash flow statements`,
        text: t`Extract company cash flow information for use in your own financial forecasting.`,
        image: useCases13,
      },
    ],
  },
  {
    label: `Supply Chain`,
    title: t`Capture supply chain data efficiently`,
    text: t`Leverage automated form field capture to streamline your supply chain processes.`,
    items: [
      {
        title: t`Shipping orders`,
        text: t`Automate data entry of shipping orders to streamline order fulfilment.`,
        image: useCases21,
      },
      {
        title: t`Delivery notes`,
        text: t`Capture delivery note information to improve your customer record-keeping.`,
        image: useCases22,
      },
      {
        title: t`Contracts`,
        text: t`Pull all the essential information you need from vendor contracts.`,
        image: useCases23,
      },
    ],
  },
  {
    label: t`Invoices`,
    title: t`Automate invoice data collection`,
    text: t`Process all the essential order information your business needs quickly and efficiently.`,
    items: [
      {
        title: t`Purchase orders`,
        text: t`Extract all order information to streamline order fulfilment & management.`,
        image: useCases31,
      },
      {
        title: t`Goods receipts`,
        text: t`Record vendor receipts and delivery notes for efficient customer record-keeping.`,
        image: useCases32,
      },
      {
        title: t`Invoices`,
        text: t`Extract all key invoice information and send it directly to your accounting systems.`,
        image: useCases33,
      },
    ],
  },
];

export function HomepageSectionUseCases() {
  const useCasesSectionItems = getUseCasesSectionItems();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [transitionTo, setTransitionTo] = useState<number | null>(null);

  const handleChangeIndex = useCallback((index: number) => {
    setTransitionTo(index);
  }, []);

  const handleAfterLeave = useCallback(() => {
    if (transitionTo === null) return;
    setSelectedIndex(transitionTo);
    setTransitionTo(null);
  }, [transitionTo]);

  return (
    <div className="bg-highlight-200">
      <Section id="use-cases">
        <Tab.Group selectedIndex={selectedIndex} onChange={handleChangeIndex}>
          <Tab.List className="mb-5 flex pb-5 md:mb-16 md:justify-center md:gap-x-12 md:pb-0">
            {useCasesSectionItems.map(({ label }, index) => (
              <Tab
                key={label}
                className="md:px-3"
                selectedOverride={
                  transitionTo !== null
                    ? transitionTo === index
                    : selectedIndex === index
                }
              >
                {label}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            {useCasesSectionItems.map(({ title, text, items }, index) => (
              <Tab.Panel key={index}>
                <Transition
                  show={index === selectedIndex && transitionTo === null}
                  appear
                  enter="transition duration-500 origin-top"
                  enterFrom="opacity-0 md:scale-95"
                  enterTo="opacity-100"
                  leave="transition duration-200 origin-top"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0 md:scale-95"
                  afterLeave={handleAfterLeave}
                >
                  <Transition.Child
                    enter="transition-transform duration-300"
                    enterFrom="-translate-x-1/4 md:translate-x-0"
                    enterTo="translate-x-0"
                    leave="transition-transform duration-300"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-1/4 md:translate-x-0"
                  >
                    <div className="my-10 md:my-16">
                      <Typography
                        variant="h2"
                        className="mb-6 md:mb-6 md:text-center"
                        gutterBottom={false}
                      >
                        {title}
                      </Typography>
                      <Typography
                        paragraph
                        className="md:text-center"
                        gutterBottom={false}
                      >
                        {text}
                      </Typography>
                    </div>
                  </Transition.Child>
                  <div className="grid grid-cols-1 gap-14 md:grid-cols-3 md:gap-8">
                    {items.map(({ title, text, image }, index) => {
                      return (
                        <div className="overflow-x-hidden" key={title}>
                          <Transition.Child
                            enter="transition-transform duration-300"
                            enterFrom={clsx({
                              "translate-x-1/4 md:translate-x-0":
                                index % 2 === 0,
                              "-translate-x-1/4 md:translate-x-0":
                                index % 2 !== 0,
                            })}
                            enterTo="translate-x-0"
                            leave="transition-transform duration-300"
                            leaveFrom="translate-x-0"
                            leaveTo={clsx({
                              "translate-x-1/4 md:translate-x-0":
                                index % 2 === 0,
                              "-translate-x-1/4 md:translate-x-0":
                                index % 2 !== 0,
                            })}
                          >
                            <div className="mb-4">
                              <Image
                                alt={title}
                                src={image}
                                placeholder="blur"
                                className="rounded-[10px] bg-highlight-300"
                                lazyBoundary="400px"
                              />
                            </div>
                            <Typography variant="h4">{title}</Typography>
                            <Typography paragraph gutterBottom={false}>
                              {text}
                            </Typography>
                          </Transition.Child>
                        </div>
                      );
                    })}
                  </div>
                </Transition>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </Section>
    </div>
  );
}
